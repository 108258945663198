export const module = {
  info: {
    namespace: 'app',
    name: 'user-manager',
    caption: 'User',
  },
  vuexModules: ['api', 'state'],
  mainRoute: '/cpanel'
}

export default module
